<template>
<v-dialog width="600" v-model="dialog">
  <v-toolbar>
    <v-container fluid>
      <div class="d-flex">
        <div class="text-h6">关键成果权重设置</div>
        <v-spacer></v-spacer>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </div>
    </v-container>
  </v-toolbar>
  <v-card>
    <v-container fluid>
      <div v-if="!!object" class="pa-8">
        <div class="d-flex my-n6 align-center" v-for="(kr, i) in object.ob_results">
          <div class="blue--text text-subtitle-2">KR{{ kr.kr_order }}</div>
          <div v-if="kr.kr_name.length>20" class="ml-3">{{ kr.kr_name.slice(0,20) }}...</div>
          <div v-else class="ml-3">{{ kr.kr_name }}</div>
          <v-spacer></v-spacer>
          <div class="grey--text text-subtitle-1 mr-2">权重</div>
          <div style="width:100px;">
            <v-text-field v-model="kr.kr_rate">
              <template v-slot:append>
                <div class="grey--text text-subtitle-2 mt-1">%</div>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="d-flex px-6">
        <v-spacer></v-spacer>
        <v-btn @click="dialog=false" class="mx-1" small>取消</v-btn>
        <v-btn @click="agree" class="mx-1" color="primary" small>确定</v-btn>
      </div>
    </v-container>
  </v-card>
</v-dialog>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
export default {
  name: 'ResultRateDlg',
  data: () => ({
    dialog: false,
    object: null,
  }),
  computed: {
    ...mapGetters('okr', ['okrObj']),
  },
  methods: {
    ...mapActions('app', ['setNotification']),
    ...mapActions('okr', ['updateKeyResult']),
    open(objectId) {
      this.dialog = true;
      const index = this.okrObj.findIndex(ob => ob.ob_id == objectId);
      this.object = Object.assign({}, this.okrObj[index]);
    },
    agree() {
      let total = 0;
      this.object.ob_results.map(kr => {
        total += Number(kr.kr_rate);
      });
      if (total != 100) {
        this.setNotification({
          status: 'warning',
          text: '关键成果总权重须等于100%'
        })
      } else {
        this.object.ob_results.map(kr => {
          const obj = {
            kr_id: kr.kr_id,
            kr_rate: kr.kr_rate
          };
          const payload = {
            obj: obj,
            kr: kr
          };
          this.updateKeyResult(payload);
          this.dialog = false;
        })

      }
    }
  }
}
</script>
